
@media (max-width: 1699px) {
    .hero-content h1 {
        margin: 0 0 15px;
        font-size: 44px;
    }
    .hero-section {
        padding: 160px 0 240px;
    }
    .hero-content h4 {
        margin: 0 0 17px;
        font-size: 15px;
    }
    .hero-content p {
        font-size: 15px;
    }
    .default-button {
        min-height: 46px;
        padding: 5px 26px;
        font-size: 15px;
        border-width: 1px;
    }
    .hero-content .button-container .button {
        margin-right: 6px;
    }
    .hero-content .button-container {
        margin: 35px 0 0;
    }



    .section-title h2 {
        margin: 0 0 16px;
        font-size: 35px;
    }
    .section-title h4 {
        margin: 0 0 11px;
        font-size: 11px;
    }
    .section-title p {
        margin: 0 0 26px;
        font-size: 14px;
    }
    .new-experience-single .icon {
        width: 30px;
        margin: 0 0 25px;
    }
    .new-experience-single h3 {
        margin: 0 0 25px;
        font-size: 16px;
    }
    .new-experience-single p {
        font-size: 13px;
        font-weight: 400;
        color: #777E90;
        line-height: 1.7;
    }



    .how-work-single .icon {
        margin: 0 0 50px;
        height: 65px;
        width: 65px;
    }
    .how-work-single .how-work-dot-img {
        right: 27px;
        top: 30px;
        width: 230px;
    }
    .how-work-single p {
        font-size: 13px;
    }
    .how-work-single h5 {
        margin: 0 0 34px;
    }
    .how-work-single h3 {
        margin: 0 0 16px;
    }



    .single-testimonial-slide .content p {
        font-size: 17.5px;
    }
    .single-testimonial-slide .slide-meta .text h4 {
        margin: 0 0 3px;
        font-size: 17.5px;
    }
    .single-testimonial-slide .slide-meta .text p {
        font-size: 13px;
    }



    .small-text-section .section-title h2 {
        font-size: 44px;
    }



    .section-title--2 .left h2 {
        font-size: 47px;
    }
    .section-title--2 .right p {
        font-size: 18.2px;
    }
    .grid1-section {
        padding: 80px 0 90px;
    }
    .grid1-grid {
        padding: 55px 0 0;
        grid-gap: 30px;
    }



    .team-member .image {
        height: 130px;
        width: 130px;
        margin: 0 0 25px;
    }
    .team-member .text h3 {
        font-size: 19px;
    }
    .team-member .text p {
        font-size: 14px;
    }
    .team-section-inner {
        max-width: 1050px;
    }


    .chart1-section .content-side .section-title p {
        font-size: 18px;
    }
    .chart1-section .content-side .section-title h2 {
        margin: 0 0 23px;
    }
    .chart1-ul li {
        font-size: 14px;
        margin: 0 0 18px;
    }
    .chart1-ul li i {
        margin: 0 8px 0 0;
        font-size: 12px;
    }
    .chart1-button-container {
        margin: 40px 0 0;
    }


    .faq-section-inner {
        max-width: 900px;
    }
    .single-faq .toggler-header h3 {
        font-size: 14px;
    }
    .single-faq .toggler-header {
        padding: 26px 0;
    }
    .single-faq .single-faq-content h3 {
        font-size: 14px;
        margin: 0 0 9px;
    }
    .single-faq .single-faq-content p {
        font-size: 13px;
        margin: 0 0 15px;
    }
    .single-faq .media-side .video-element {
        border-radius: 15px;
        height: 222px;
    }
    .faq-nav ul li label {
        padding: 7px 12px;
        font-size: 12px;
    }
    .faq-nav ul li {
        margin-right: 5px;
    }


    .pricing-section-inner {
        padding: 70px 150px 110px;
    }
    .pricing-table .price-detail-title h4 {
        font-size: 14px;
    }
    .pricing-table .table-body-row .column {
        padding: 15px 0;
    }
    .pricing-table .price-card-head h2 {
        margin: 0 0 7px;
        font-size: 35px;
    }
    .pricing-table .price-card-head h2 span {
        font-size: 20px;
        margin: -5px 8px 0 0;
    }
    .pricing-table .price-card-head h3 {
        font-size: 14px;
        margin: 0 0 10px;
    }
    .pricing-table .price-card-head h4 {
        font-size: 12px;
        margin: 0 0 20px 0;
    }
    .pricing-table .table-heading-row .column {
        padding: 20px 0 30px;
    }



    .section-a-inner {
        padding: 100px 120px;
    }
    .section-a-top-row .content-side .icon-logo {
        width: 65px;
        margin: 0 0 13px;
    }
    .section-a-top-row .content-side h3 {
        margin: 0 0 15px;
        font-size: 19px;
    }
    .section-a-top-row .content-side p {
        margin: 0 0 33px;
        font-size: 12px;
    }
    .section-a-top-row .content-side p .first-quote {
        width: 19px;
        margin: 0 8px 0 0;
    }


    .dark-section-title {
        padding: 65px 15px 40px;
        border-radius: 10px;
    }



    .contact-us-section-row {
        padding: 100px 200px 70px;
    }
    .form-model .form-title {
        padding: 0 0 50px;
    }
    .form-model .input {
        font-size: 15px;
        padding: 15px 22px;
        border-radius: 5px;
    }
    .form-model .single-input {
        margin: 0 0 8px;
    }
    .form-model .slim-submit-button {
        margin: 16px 0 0;
        padding: 11px 20px;
        font-size: 13px;
    }
    .contact-details-single h3 {
        margin: 0 0 22px;
        font-size: 17px;
    }
    .contact-details-single p {
        margin: 0 0 8px;
        font-size: 14px;
    }
    .contact-details-single {
        margin: 0 0 36px;
    }


    .terms-nav ul li a {
        padding: 9px 25px;
        font-size: 14px;
    }
    .terms-nav {
        padding: 14px 0;
        margin: 0 35px 0 0;
        width: 220px;
        border-radius: 8px;
    }
    .terms-tab {
        font-size: 14px;
    }
    .terms-tab p {
        margin: 0 0 25px;
    }
    .terms-tab h3 {
        margin: 0 0 15px;
        font-size: 18px;
    }
    .terms-tab ul {
        margin: 0 0 30px;
    }
    .terms-tab ul li {
        padding: 0 0 8px 5px;
    }


    .investment-section {
        padding: 70px 0 40px;
    }
    .select-style {
        height: 38px;
        padding: 0 11px;
        min-width: 145px;
        border-radius: 8px;
        font-size: 12px;
    }
    .investment-filter-select-row {
        padding: 0 70px;
    }
    .investment-main {
        margin: 20px 0 0;
        padding: 60px 60px;
    }
    .investment-nav {
        width: 480px;
        margin: 0 35px 0 0;
        padding: 0 27px 0 0;
    }
    .investment-nav .nav-item-box .heading h3 {
        font-size: 19px;
    }
    .investment-nav .nav-item-box .heading .inner-heading {
        padding: 15px 22px;
    }
    .investment-nav .nav-item-box {
        border-radius: 11px;
    }
    .investment-nav ul li {
        margin: 0 0 9px;
    }
    .investment-nav .nav-item-box .content-part {
        padding: 6px 21px;
    }
    .investment-nav .nav-item-box .content-part .extended-heading {
        padding: 7px 0 14px;
    }
    .investment-nav .nav-item-box .content-part .extended-heading p {
        font-size: 12px;
    }
    .investment-nav .nav-item-box .content-part .content-main .data-row .column h4 {
        margin: 0 0 7px;
        font-size: 11px;
    }
    .investment-nav .nav-item-box .content-part .content-main .data-row .column p {
        font-size: 11px;
    }
    .investment-table td {
        padding: 11px 7px;
        font-size: 11px;
    }



    .account-process-form .title-form h3 {
        margin: 0 0 9px;
        font-size: 45px;
    }
    .account-process-form .title-form p {
        font-size: 17px;
    }
    .account-process-form {
        padding: 35px;
        width: 420px;
    }



    .form-model .overlay-link {
        right: 10px;
        font-size: 11px;
    }
    .form-model .fgt-pass .input {
        padding-right: 120px;
    }
}


@media (max-width: 1399px) {

}


@media (max-width: 1320px) {
    .pricing-section-inner {
        padding: 70px 50px 110px;
    }


    .section-a-inner {
        padding: 100px 40px;
    }


    .contact-us-section-row {
        padding: 100px 100px 70px;
    }


    .investment-main {
        flex-wrap: wrap;
    }
    .investment-nav {
        width: 100%;
        margin: 0 0px 0 0;
        padding: 0 0px 25px 0;
        border: none;
    }
    .investment-filter-select-row .single-select {
        margin: 0 0 16px;
    }
    .investment-filter-select-row {
        padding: 0 0px;
    }
    .investment-tab-content {
        width: 100%;
    }
}

@media (max-width: 1199px) {
    .section-title--2 .left h2 {
        font-size: 35px;
    }
    .section-title--2 .right p {
        font-size: 13.1px;
    }



    .chart1-section .content-side .section-title p {
        font-size: 14px;
    }



    .team-member .image {
        height: 100px;
        width: 100px;
        margin: 0 0 19px;
    }
    .team-member .text h3 {
        font-size: 16px;
    }
    .team-member .text p {
        font-size: 12px;
    }
    .team-section-inner {
        max-width: 680px;
    }
}


@media (max-width: 991px) {
    .hero-section {
        padding: 130px 0 190px;
    }



    .new-experience-wrap {
        padding: 60px 0 0;
        flex-wrap: wrap;
    }
    .new-experience-single {
        width: 100%;
        padding: 30px 6.2%;
        border-right: none;
        border-bottom: 1px solid #E6E8EC;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }



    .how-work-wrap {
        padding: 50px 0 0;
        flex-wrap: wrap;
    }
    .how-work-single {
        padding: 0;
        margin: 0 0 50px;
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .how-work-single .how-work-dot-img {
        display: none;
    }
    .how-work-single .icon {
        margin: 0 0 20px;
    }
    .how-work-single h5 {
        margin: 0 0 15px;
    }



    .why-choose-side-img {
        display: none;
    }



    .section-title--2 {
        flex-wrap: wrap;
    }
    .section-title--2 .left {
        width: 100%;
        margin-right: 0;
    }


    .chart1-section-inner {
        flex-wrap: wrap;
    }
    .chart1-section .content-side {
        width: 100%;
    }
    .chart1-section .chart-image-side {
        width: 100%;
        order: -1;
        margin: 0 0 30px;
        padding: 0 10%;
    }



    .single-faq .media-side {
        width: 50%;
        margin-right: 5%;
    }
    .faq-nav {
        margin: 18px 0 0;
    }
    .faq-wrap {
        padding: 60px 0 0;
    }
    .single-faq .toggler-header {
        padding: 20px 0;
    }



    .section-a-top-row {
        flex-wrap: wrap;
    }
    .section-a-top-row .content-side {
        width: 100%;
    }
    .section-a-top-row .image-side {
        width: 100%;
        padding: 40px 10% 0;
    }
    .section-a-second-row-item {
        width: 50%;
        margin: 0 0 35px;
    }


    .contact-us-section-row {
        flex-wrap: wrap;
    }
    .contact-us-section-row .contact-details {
        width: 100%;
        padding-left: 0;
        margin-left: 0;
        border-left: none;
        border-top: 1px solid #E6E8EC;
        margin-top: 40px;
        padding-top: 40px;
    }


    .terms-section-main {
        flex-wrap: wrap;
    }
    .terms-nav {
        margin: 20px 0px 0 0;
        width: 100%;
        order: 2;
    }




    .investment-table {
        padding: 25px 5px 28px 20px;
        border-radius: 12px;
    }
    .investment-tab .bottom-button-container {
        margin: 30px 0 0;
    }
}


@media (max-width: 768px) {
    .hero-section {
        padding: 120px 0 160px;
    }


    .brands-wrap {
        flex-wrap: wrap;
    }
    .brands-wrap .single-brand {
        width: 33.33333%;
        padding: 0 1.1% 5px;
    }
    .brand-section {
        padding: 80px 0 60px;
    }



    .small-text-section-inner {
        padding: 90px 0 60px;
    }



    .testimonial-section {
        padding: 80px 0 100px;
    }
    .testimonial-slider {
        margin: 40px 0 0;
    }



    .how-work-wrap {
        padding: 30px 0 0;
    }
    .how-work-section {
        padding: 90px 0 60px;
    }



    .why-choose-section {
        padding: 80px 0 40px;
    }
    .why-choose-content {
        padding: 30px 0 0;
    }
    .why-choose-single .number-tag {
        margin: 0 0 22px;
        
    }
    .why-choose-single h3 {
        margin: 0 0 14px;
        font-size: 15px;
    }
    .why-choose-single {
        margin: 0 0 40px;
    }



    
    .new-experience-section {
        padding: 80px 0 60px;
    }


    .section-title--2 .left h2 {
        font-size: 30px;
    }
    .grid1-section {
        padding: 65px 0 70px;
    }
    .grid1-grid .grid-item {
        grid-area: span 1/ span 4 !important;
    }
    .grid1-grid {
        padding: 15px 0 0;
        grid-gap: 20px;
    }



    .team-section {
        padding: 70px 0 20px;
    }
    .team-member {
        margin: 0 0 50px;
        width: 33.33333%;
    }
    .chart1-section {
        padding: 50px 0 60px;
    }



    .section-title h2 {
        margin: 0 0 16px;
        font-size: 30px;
    }


    .single-faq .collapsible-content-inner {
        flex-wrap: wrap;
    }
    .single-faq .media-side {
        width: 100%;
        margin: 0 0 20px;
    }


    .section-a-inner {
        padding: 70px 40px;
    }



    .contact-us-section-row {
        padding: 70px 0px 70px;
    }
    .form-model .form-title {
        padding: 0 0 30px;
    }


    .terms-section-main {
        padding: 35px 0 45px;
    }
    .terms-tab {
        font-size: 13px;
    }
    .terms-tab h3 {
        font-size: 16px;
    }



    .investment-main {
        padding: 40px 20px;
    }
}


@media (max-width: 575px) {
    .pricing-section-inner {
        padding: 70px 20px 80px;
        width: calc(100% + 24px);
        margin-left: -12px;
    }



    .section-a-inner {
        width: calc(100% + 24px);
        margin-left: -12px;
    }
    .section-a-inner {
        padding: 40px 15px;
    }


    .investment-main {
        width: calc(100% + 24px);
        margin-left: -12px;
    }


    .investment-nav .nav-item-box .content-part .content-main .data-row {
        flex-wrap: wrap;
    }
    .investment-nav .nav-item-box .content-part .content-main .data-row .column {
        width: 50%;
        margin: 0 0 20px;
    }
    .investment-nav .nav-item-box .heading h3 {
        font-size: 17px;
    }


    .account-process-section {
        padding: 60px 0;
      }
}


@media (max-width: 460px) {
    .team-member {
        margin: 0 0 50px;
        width: 50%;
    }

    .section-a-second-row-item {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .section-a-second-row-item .image {
        margin: 0 0px 10px 0;
    }
    .section-a-top-row .content-side h3 {
        font-size: 17px;
    }


    .investment-main {
        padding: 30px 12px;
    }
}


@media (max-width: 390px) {
    .single-faq .toggler-header .chevron {
        font-size: 10px;
        margin: 0 3px 0 30px;
    }
    .single-faq .media-side .video-element {
        border-radius: 10px;
        height: 170px;
    }
    .single-faq .toggler-header {
        padding: 16px 0;
    }


    .account-process-form {
        padding: 25px 15px;
    }
}
