@import "../font/css/all.css";
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
/*@import "../font/CaviarDreams.ttf";
@import "../font/CaviarDreams_Bold.ttf";*/

@font-face {
  font-family: "CaviarDreams_Bold";
  /*src: url('Caviar_Dreams_Bold-webfont.eot');
  src: url('Caviar_Dreams_Bold-webfont.eot?#iefix') format('embedded-opentype'); 
  src: url('Caviar_Dreams_Bold-webfont.woff') format('woff');*/
  src: url("../font/CaviarDreams_Bold.ttf") format("truetype");
  /*src: url('Caviar_Dreams_Bold-webfont.svg#CaviarDreamsBold') format('svg');*/
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "CaviarDreams";
  /*src: url('CaviarDreams-webfont.eot');
  src: url('CaviarDreams-webfont.eot?#iefix') format('embedded-opentype'); 
  src: url('CaviarDreams-webfont.woff') format('woff');*/
  src: url("../font/CaviarDreams.ttf") format("truetype");
  /*src: url('CaviarDreams-webfont.svg#CaviarDreamsRegular') format('svg');*/
  font-weight: normal;
  font-style: normal;
}

ul {
  padding: 0;
}

ul li {
  list-style-type: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.margin_h1_test {
  display: block;
}

h1,
h2,
h3,
h4,
h5 {
  /* font-family: 'CaviarDreams', sans-serif; */
  /* font-family: "CaviarDreams"; */
  font-weight: bold;
  /* font-style:italic; */
}

.hh_0 {
  margin-block: 0;
  margin-bottom: 0;
  margin-top: -1rem!important;
    line-height: .9;
}

.lrg_1h {
  font-size: 6rem;
  margin-top: -1.294rem;
}

h1 {
  margin-top: -0.5rem;
  font-size: 3.5rem;
}

h2 {
  margin-top: -0.36rem;
  font-size: 2.5rem;
}

h3 {
  margin-top: -0.3rem;
}

h4 {
  margin-top: -0.29rem;
}

body,
div,
p,
a {
  font-size: 1rem;
}

.btn {
  font-size: 1.2rem;
}

a:hover {
  text-decoration: none;
  color: #fff !important;
  transition: all 0.8s ease-in-out;
}

button:hover {
  text-decoration: none;
  color: #fff !important;
  transition: all 0.8s ease-in-out;
}

a:focus {
  outline: none;
}

.btn:focus {
  box-shadow: inherit;
}



.btn.grd_btn {
  text-decoration: none;
  color: #000 !important;
  background-image: linear-gradient(
    to left,
    #fa9014,
    #f77e13,
    #f26b15,
    #ed5719,
    #e7401e
  );
  
  transition: all 0.8s ease-in-out;
  text-transform: capitalize;
}
.btn.grd_btn:hover {
  text-decoration: none;
  color: #061a8a !important;
}
.form-control:focus {
  box-shadow: inherit;
}

.d_flex {
  display: flex;
}

.mt_80 {
  margin-top: 5rem;
}

.mb_80 {
  margin-bottom: 5rem;
}

.cu_m_0 {
  margin: 0;
}

.cu_p_0 {
  padding: 0;
}

.cu_my_5 {
  padding: 3rem 0rem;
}

.cu_my_4 {
  padding: 1.5rem 0rem;
}

.nt_chld_aftr li:not(:first-child):after {
    content: '|';
    position: absolute;
    top: 11px;
    color: #fff;
    left: 0px;
    font-size: 25px;
}

.nt_chld li:not(:first-child) {
    margin-left: 40px;
    padding-left: 40px;
}

.img_fluid img {
  max-width: 100%;
  height: auto;
}

.cu_container_fluid {
  width: 100%;
  margin-top:60px;
  max-width: 1365px;
}

/*.lrg_txt {
  font-size: clamp(21px, 1.5rem, 140px) !important;
  font-weight: 600;
}*/

.ext_lrg_txt {
  /*font-size: clamp(31px, 2rem, 140px) !important;*/
  font-weight: 600;
}

.bg_gry {
  background: #f0f0f0;
}

.bg_grf {
  background: #000;
}

.bg_pic_right {
  background-image: url(../images/bg_pic_r.png);
  background-position: center right;
  background-repeat: no-repeat;
  background-size: contain;
}
.bg_pic_left_main {
  background-image: url(../images/bg_pic_l.png);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: contain;
}
.bg_pic_left {
  background-image: url(../images/bg_pic_l.png);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: linear-gradient(
    to right,
    #fa9014,
    #f77e13,
    #f26b15,
    #ed5719,
    #e7401e
  );
}

.bg_pic_left_right {
  background-color: #000;
  background-image: url(../images/bg_pic_r.png), url(../images/bg_pic_l.png);
  background-position: right center, left center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 60px;
  margin-bottom:40px;
}

/**********  sticky nav bar ************/

.navbar {
  -webkit-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
  padding: 30px;
  font-size: 20px;
  /*background: #0CF;*/
  height: 85px;
}

.sticky {
  position: fixed;
  top: 0px;
  color: #000;
  z-index: 9;
  width: 100%;
  padding: 15px 10px;
  height: 50px;
  margin: 0px;
  opacity: 1;
  background: #000;
}

.fxd {
  padding: 0px 0px;
  background: red;
  -webkit-transition: all 0.1s ease 0s;
  transition: all 0.1s ease 0s;
  color: green;
}

.fxd.sticky {
  top: -20px;
  height: 20px;
  margin-bottom: 15px;
}

.fxd.sticky .navbar-brand {
  width: 70px;
}

/**********  sticky nav bar end ************/

/**********  nav start ************/

.navbar a.nav-link {
  font-size: 1.2rem;
  line-height: 40px;
  color: #dd1526;
  cursor: pointer;
}

.navbar li.nav-item:not(:last-child) {
  margin-left: 1.0rem;
}

.navbar .active a.nav-link,
.active a {
  font-weight: 600;
}

.navbar-nav > li {
  position: relative;
}

.navbar-nav > li > a:hover::after {
  top: -60px;
  opacity: 1;
}

.navbar-nav > li > a::after {
  position: absolute;
  top: -80px;
  height: 120%;
  width: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: transparent;
  /*background: linear-gradient( 180deg, rgba(0, 255, 255) 0%, rgba(50, 0, 255, 1) 100%);*/
  content: "";
  opacity: 0;
  transition: all 0.8s ease-in;
}

.navbar-nav > li.active > a::after {
  top: -60px;
  opacity: 1;
}

.navbar-nav > li > div > button::before {
  position: absolute;
  top: -80px;
  height: 120%;
  width: 2px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #3095ce;
  content: "";
  opacity: 0;
  transition: all 0.8s ease-in;
  
}

.navbar-nav > li:hover > div > button::before {
  top: -60px;
  opacity: 1;
}

.navbar-nav > li.active > div > button::before {
  top: -60px;
  opacity: 1;
}
/**********   nav end  ************/

.brk {
  display: block;
}

/**********  banner start ************/

.banner_bx {
  position: relative;
}

.banner_cont {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 3rem;
  background: #0ff;
}

.banner_cont h1 {
  color: #db0625;
}

.cu_h-100 {
  height: 70%;
}

.hom_bnr.cu_h-100 {
  height: 83%;
}

/**********  banner end ************/

/**** about start *****/

/**** about end *****/

/**** about next start *****/

.bg_blu_grad {
  background-image: linear-gradient(
    to right,
    #fa9014,
    #f77e13,
    #f26b15,
    #ed5719,
    #e7401e
  );
  width: 21.5%;
  height:250px;
  padding: 1.8rem;
  color: #000;
  padding-top: 3.5rem;
  position: relative;
}

.blu_bg_icn_bx {
  margin-bottom: 1rem;
}

.bg_blu_grad:last-child {
  width: 33%;
}

.bg_blu_grad a {
  position: absolute;
  bottom: 2.5rem;
}

/**** aboutnext end  *****/

/**** you are in good start  *****/

.com_logo {
  width: 32%;
}

/**** you are in good end  *****/

/******* content end ********/

.slick-slide img {
  display: block;
}

.sldr {
  max-width: 70vw;
  width: 74%;
  overflow: hidden;
  right: -19%;
  position: absolute;
  z-index: 99999;
}

.prt_bx {
  padding-right: 15px;
}

.bg-cr {
  padding-top: 3rem;
  /* padding-bottom:4.4rem; */
  padding-bottom: 3.5rem;
}

.wid_40 {
  width: 30%;
}

.else_cont {
  position: absolute;
  left: 0;
  right: 0;
  top: 6em;
  bottom: 0;
}

.sldr .slick-dots {
  text-align: left;
}

.slick-dots li.slick-active button:before {
  font-family: "slick";
  content: "•";
  opacity: 1;
  color: #219abe !important;
  font-size: 10px !important;
  line-height: 16px;
}

.slick-dots li button:before {
  font-family: "slick";
  font-size: 15px !important;
  line-height: 10px;
  content: "o";
  opacity: 1 !important;
  color: #000 !important;
}

.slick-dots li button:hover:before,
.slick-dots li {
  color: #000 !important;
}

.slick-dots {
  position: absolute;
  bottom: -40px;
}

/******* content end ********/

/******* footer start ********/

.footer__sec .cu_container_fluid {
  padding: 0px;
}
.footer__sec_background{
  background-color: #000;
  margin-top: -50px;
  padding-top: 50px;
}
.ftr_nv li a {
  color: #dd1526;
}

.ftr_sghn_bx {
  margin-bottom: 1rem;
}

.ftr_sghn_bx .ftr_inp_fld {
  padding: 20px;
  margin-right: 8px;
  background: #061a8a;
  color: #061a8a;
  border: none;
}
.ftr_inp_fld::placeholder{color:#061a8a; opacity: 1;}
.ftr_inp_fld:-ms-input-placeholder{color:#061a8a;}
.ftr_inp_fld::-ms-input-placeholder{color:#061a8a;}
.ftr_inp_fld:focus{background:#061a8a;color:#061a8a}
.ftr_inp_fld:focus::-webkit-input-placeholder {
    color:#061a8a;
}
.socl li {
  margin-right: 10px;
}

.bg_dark {
  background: #061a8a;
}

.cpr {
  font-size: clamp(10px, 0.9rem, 98px) !important;
}

/******* footer end ********/

/****** scroll to top button start  *****/

.back-to-top {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: none;
}

/****** scroll to top button end  *****/

/**** style 1 efct_zom css start ****/

.efct_zom .zom_pic {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -o-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.efct_zom:hover .zom_pic {
  -webkit-transform: scale(1.5, 1.5);
  /* scale(10)
  -moz-transform: scale(1.5, 1.5);
  /* scale(10)*/
  -o-transform: scale(1.5, 1.5);
  /* scale(10)*/
  -ms-transform: scale(1.5, 1.5);
  /* scale(10)*/
  transform: scale(1.5, 1.5);
  /* scale(10)*/
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=1)";
  filter: alpha(opacity=1);
  opacity: 1;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
}

.efct_zom {
  overflow: hidden;
}

/**** style 1 efct_zom css end ****/

.hvr_clr:hover {
  border: 2px solid #3f51b5;
  transition: all 0.7s ease-in-out;
}

.hvr_clr:hover h6 {
  color: #3f51b5;
  transition: all 0.7s ease-in-out;
}

.hvr_clr h6,
.hvr_clr p {
  padding-left: 1rem !important;
}

/************====
other page css start

=====***************/

/************====pricing css start=====***************/

.pricing_box {
  width: 32%;
  border-radius: 6px 6px 0px 0px;
  overflow: hidden;
  padding-bottom: 15px !important;
}

.pricng_hdr {
  background-image: linear-gradient(
    to bottom,
    #fa9014,
    #f77e13,
    #f26b15,
    #ed5719,
    #e7401e
  );
  margin-top: 0.3rem;
  color: #000;
}

.pricng_hdr h3 {
  padding: 0.8rem 0px;
  text-transform: capitalize;
}

/************====pricing css end=====***************/

/************====content dtl page css start=====***************/

/*.cnt_dtl_pic_box {
    width: 24%;
    min-height: 360px;
}*/

.cnt_dtl_pic_box {
    border-radius: 5px 5px 5px 5px;
    overflow: hidden;
}

.cont_dtl_pic_dec {
  min-height: 200px;
}

.maindiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.cnt_dtl_pic_box {
  border-radius: 5px 5px 5px 5px;
  overflow: hidden;
  min-height: 360px;
  margin: 10px;
  background: #fff;
  border: 2px solid green;
  flex: 0 1 calc(25% - 20px);
  background-image: linear-gradient(
    to right,
    #fa9014,
    #f77e13,
    #f26b15,
    #ed5719,
    #e7401e
  );
}
a.cnt_dtl_pic_box {
  color: #000;
  cursor: pointer;
}
.cu_row {
  margin-right: -12px;
  margin-left: -12px;
}

/************====content dtl page css end=====***************/

/************====sign in page css start=====***************/

.scl_sgn_bx .btn.btn.grd_btn {
  color: #fff !important;
}

.scl_sgn_bx {
  width: 30%;
}

.or_bx {
  width: 7%;
}

.scl_btn_bx {
  width: 63%;
}

.sgn_socls {
  width: 49%;
}

a.sgn_socls {
  font-size: clamp(13px, 0.8rem, 50px) !important;
  align-items: center;
}

button.sgn_socls {
  color: #dd1526;
  font-size: clamp(13px, 0.8rem, 50px) !important;
  align-items: center;
}

/************====sign in  page css end=====***************/

/************===terms  page css start=====***************/

.active .tb_arow {
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    transform-origin: 20% 40%;
}

.terms_tb {
  justify-content: space-between;
}

.terms_tb li {
  width: 50%;
}

.terms_tb li.tb1hd {
  border-radius: 8px 9px 0px 0px;
  border: 1px solid #ccc;
  background: #fff;
  border-right: none;
  border-bottom: none;
}

.terms_tb li.tb2hd {
  border-radius: 0px 0px 0px 15px;
  border: 1px solid #ccc;
  border-top: none;
  margin-left: -15px;
  margin-top: 8px;
  border-right: 0;
}

.terms_tb li a {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  color: #1b1b1b;
  position: relative;
}

.terms_tb li.tb1hd a {
  border-bottom: 1px solid #ccc;
  margin-right: 12px;
  margin-left: 12px;
}

.terms_tb li.tb2hd a {
  border: 1px solid #ccc;
  background: #000;
  /* margin: -9px 13px 10px; */
  margin: -7px 0px 9px 10px;
  border-radius: 10px;
}

.terms_tb.nav-tabs {
  border-bottom: 0px solid #ccc;
}

.terms_tb li a span {
  border-radius: 12px !important;
  color: #000;
}

/* *********** tab curve ssss*********** */
.terms_tb li.tb1hd a.active {
  border-bottom: 1px solid #ccc;
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 8px 9px 0px 0px;
  border: 1px solid orangered;
  background: #000;
  border-right: none;
  border-bottom: none;
  padding-left: 15px;
  margin-top: -14px;
}

.terms_tb li.tb1hd a {
  border-bottom: 1px solid orangered;
  margin-right: 10px;
  margin-left: 0px;
  /* border-radius: 8px 9px 0px 0px; */
  border: 1px solid orangered;
  background: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
  border-left: none;
  border-radius: 0px 2px 10px 0px;

  margin-right: 0px;
  padding-left: 0px;
}

.terms_tb li.tb1hd {
  border-radius: 8px 9px 0px 0px;
  border: 1px solid #ccc;
  background: none;
  border-right: none;
  border-bottom: none;
  border: none;
  margin-top: 14px;
}

.terms_tb li.tb2hd a {
  border-radius: 0px 0px 0px 15px;
  border: 1px solid orangered;
  border-top: none;
  margin-left: -15px;
  margin-top: 8px;
  border-right: 0;
  margin: -7px 0px 7px 0px;
  background: none;
  padding-right: 0;
}

.terms_tb li.tb2hd a.active {
  border-radius: 9px 9px 0px 0px;
  border: 1px solid orangered;
  border-left: none;
  border-bottom: none;
  background: #000;
  padding-right: 15px;
}

.terms_tb li.tb2hd a span {
  margin-top: -0px !important;
}
.terms_tb li.tb2hd a.active span {
  margin-top: 14px !important;
}

.terms_tb li.tb2hd {
  border-radius: 0px 0px 0px 15px;
  border: 1px solid #ccc;
  border-top: none;
  margin-left: -15px;
  margin-top: 14px;
  border-right: 0;
  border: none;
}

.bx_trms_tb .tab-content {
    border: 1px solid #ccc;
    /*background: #fff;*/
    border-top: none;
    margin-top: 0px;
    padding: 15px;
}
.bx_trms_tb .tab-content {
  border: 1px solid orangered;
  background: #000;
  border-top: none;
  margin-top: -8px;
  padding: 15px;
}

/* ************ tab curve eeee***************** */
.trms_page_bg {
  background-position: left top, right top;
  background-size: 82%;
}
.cu_tab_arow::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f105";
  color: #777;
  font-weight: bold;
  float: right;
  transition: all 0.8s ease-in-out;
  margin-top: 2%;
  position: absolute;
  right: 15px;
}

.tb1hd .cu_tab_arow::after {
  position: absolute;
  right: 30px;
}
.tb2hd .cu_tab_arow::after {
  position: absolute;
  right: 15px;
}

.cu_tab_arow.active::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f107";
  color: #660b0b;
  transform-origin: 20% 40%;
  margin-top: 15px;
  right: 30px;
  position: absolute;
}

.tb2hd .cu_tab_arow.active::after {
  transform-origin: 20% 40%;
  margin-top: 23px;
}

/************===terms  page css end=====***************/

/************===faq  page css  start=====***************/
.ttb {
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  background-image: linear-gradient(
    to right,
    #fa9014,
    #f77e13,
    #f26b15,
    #ed5719,
    #e7401e
  );
}
/* Style the Headings that are used to open and close the accordion panel */
.ttb h2.accordion {
  color: #000;
  cursor: pointer;
  padding: 8px;
  margin: 0;
  font-size: 18px !important;
  font-weight: 300;
  padding: 15px;
}

/* Change color of the heading and icon (on hover and click) */
.accordion:hover,
.accordion:hover::after {
  background-color: #939da7;
  color: #db0625;
}
.ttb h2.accordion.active {
  border-bottom: 1px solid #ccc;
  margin: 0px 15px;
}

.ttb .accordion::after {
  content: "\002B";
  color: #777;
  font-weight: bold;
  float: right;
}

.ttb .active::after {
  content: "\2212";
  color: #000;
}

/* Style the accordion panel */
.accordion-panel {
  padding: 0 18px;
  overflow: hidden;
  max-height: 160px;
  transition: max-height 0.2s ease-out;
}

.accordion-panel-hide {
  padding: 0 18px;
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.2s ease-out;
}

.dw {
  box-sizing: border-box;
  -moz-column-gap: 0;
  column-gap: 10px;

  -moz-column-count: 2;
  column-count: 2;
}

.dw-pnl {
  -moz-column-break-inside: avoid;
  break-inside: avoid;
}

.text-red {
  color: #dd1526;
}
.bg-orange {
  background-image: linear-gradient(
    to right,
    #fa9014,
    #f77e13,
    #f26b15,
    #ed5719,
    #e7401e
  );
}
.tabulator .tabulator-header .tabulator-col {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  background-color: whitesmoke !important;
  text-align: left;
  vertical-align: bottom;
  overflow: hidden;
}
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-sorter .tabulator-arrow {
  width: 0;
  height: 0;
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 6px solid #bbb;
}
.tabulator-row .tabulator-cell {
  font-size: 12px !important;
}
.tabulator .tabulator-header .tabulator-col .tabulator-col-content .tabulator-col-title {
  font-size: 12px !important;
  overflow: visible !important;
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl{
  padding-right: 0px !important; 
  padding-left: 0px !important;
}
/************===faq  page css end=====***************/

/************====
other page css end

=====***************/

/************=========***************/

/************=========***************/

@media (max-width: 1200px) {
  .cu_h-100 {
    height: 70%;
  }
  h2 {
    margin-top: -0.36rem;
    font-size: 2rem;
  }
  h3 {
    margin-top: -0.3rem;
    font-size: 1.5rem;
  }
  /*.container {
    width: 95%;
    max-width: 95%; 
  }*/
}

@media (max-width: 990px) {
  .brk {
    display: inline;
  }
  /*.container {
    width: 95%;
    max-width: 95%;
  }*/
  .banner_cont {
    padding-top: 0rem;
  }
  .cu_h-100 {
    height: 80%;
  }

  .hom_bnr.cu_h-100 {
    height: 74%;
  }
  .navbar {
    background: transparent;
    height: 85px;
    padding: 5px 15px;
  }
  .navbar-brand {
    width:'max-content';
  }
  .fxd.sticky .navbar-brand {
    width: 100px;
  }
  .navbar .navbar-toggler {
    color: #fff;
    font-size: 22px;
    margin-left: 5px;
  }
  .fxd.sticky .navbar {
    background: #000;
  }
  .fxd.sticky .navbar .navbar-toggler {
    color: #e9481d;
  }
  .bg_blu_grad {
    width: 50%;
  }
  .bg_blu_grad:last-child {
    width: 50%;
  }
  .bg_blu_grad a {
    bottom: 0.5rem;
  }
  .abt_parnt img {
    margin-bottom: 1.5rem;
  }
  .navbar-collapse {
    background: #06b4e0;
    z-index: 9;
  }
  .navbar-nav > li > a:hover::after {
    opacity: 0;
  }
  .navbar li:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
  .navbar li.nav-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .navbar-nav > li.active > a::after {
    opacity: 0;
  }
}

@media (max-width: 900px) {
  .cu_h-100 {
    height: 80%;
  }
}

@media (max-width: 768px) {
  html {
    font-size: 55%;
  }
  h1 {
    font-size: clamp(29px, 3rem, 198px) !important;
  }
  h2 {
    font-size: clamp(24px, 2.8rem, 150px) !important;
  }
  h3 {
    font-size: clamp(21px, 2.3rem, 140px) !important;
  }
  h6 {
    font-size: clamp(18px, 2rem, 140px) !important;
  }
  body,
  p,
  a,
  input,
  textarea,
  div {
    font-size: clamp(14px, 1rem, 50px) !important;
  }
  /*.btn {
    font-size: clamp(14px, 1rem, 140px) !important;
  }*/
  .navbar-collapse {
    background: #06b4e0;
    z-index: 9;
  }
  .navbar-nav > li > a:hover::after {
    opacity: 0;
  }
  .navbar li:not(:last-child) {
    border-bottom: 1px solid #fff;
  }
  .navbar li.nav-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .lrg_txt {
    font-size: clamp(16px, 1rem, 140px) !important;
    font-weight: 600;
  }
  .ext_lrg_txt {
    font-size: clamp(24px, 2rem, 140px) !important;
    font-weight: 600;
  }
  .btn.grd_btn {
    font-size: clamp(14px, 1rem, 140px) !important;
  }
  .ftr_sghn_bx .ftr_inp_fld {
    padding: 14px;
    margin-right: 8px;
  }
  .fotr_sec {
    text-align: center;
  }
  .scl_sgn_bx {
    width: 100%;
  }
  .or_bx {
    width: 100%;
    margin-top: 3px;
    margin-bottom: 7px;
  }
  .scl_btn_bx {
    width: 100%;
  }
  .cu_h-100 {
    height: 86%;
  }
  .hom_bnr.cu_h-100 {
    height: 74%;
  }

  /*.cnt_dtl_pic_box {
    font-size: clamp(14px, 1rem, 50px) !important;

    flex: 0 1 calc(50% - 20px);
  }*/

  .dw {
    -moz-column-count: 1;
    column-count: 1;
  }
}

@media (max-width: 580px) {
  body,
  p,
  a,
  div {
    font-size: clamp(14px, 100%, 50px) !important;
  }
  .mn_bnr {
    overflow: hidden;
  }
  .mn_bnr img {
    width: 580px;
    max-width: 600px;
  }
  .bg-cr {
    text-align: center;
    padding-bottom: 4rem;
    /* background: red; */
  }
  .sldr,
  .else_cont {
    position: inherit;
  }
  .else_cont {
    top: 5rem;
  }
  .bg-cr .sldr {
    width: 100%;
    margin-right: 0px;
  }
  .prt_bx {
    padding-right: inherit;
  }
  .sldr .slick-dots {
    text-align: center;
  }
  .wid_40 {
    width: 100%;
  }
  .ext_lrg_txt {
    font-size: clamp(16px, 2rem, 140px) !important;
    font-weight: 600;
  }
  .container {
    width: 100%;
    max-width: 100%;
  }
  .cu_container_fluid {
    padding: 0;
  }
  .hom_bnr.cu_h-100,
  .hom_text_center {
    text-align: center;
  }
  .bg_blu_grad a {
    left: 0;
    right: 0;
  }
  .navbar-nav a.nav-link {
    font-size: clamp(13px, 78%, 50px) !important;
    line-height: 30px;
  }
  .ftrlogo img {
    width: 85px;
  }
  /*.cnt_dtl_pic_box {
        width: 48%;
        text-align: center;
    }*/
}

/* only for tabb  */

@media (max-width: 520px) {
  .trms_page_bg {
    background-position: left top, right top;
    background-size: 45%;
  }
  .tb_brk {
    display: block;
  }
  .terms_tb li a {
    padding: 5px 7px;
    position: relative;
  }

  .terms_tb li.tb2hd a {
    padding-left: 17px;
    padding-right: 28px;
  }

  .cu_tab_arow::after {
    margin-top: 8%;
    position: absolute;
    right: 18px;
  }

  .tb2hd .cu_tab_arow::after {
    margin-right: -6px;
  }
  .terms_tb li.tb2hd a span {
    margin-top: -5px !important;
  }
  .cu_tab_arow.active::after {
    position: absolute;
    right: 18px;
  }

  .terms_tb li.tb2hd a.active span {
    margin-top: 8px !important;
  }
  .tb2hd .cu_tab_arow.active::after {
    margin-right: 8px;
  }

  .terms_tb li.tb2hd .cu_tab_arow::after {
    margin-top: 5%;
  }

  .terms_tb li.tb2hd .cu_tab_arow.active::after {
    margin-top: 12%;
  }

  .terms_tb li a span {
    padding-left: 9px !important;
  }
}

@media (max-width: 480px) {
  html {
    font-size: 40%;
  }
  h1 {
    font-size: clamp(23px, 2rem, 198px) !important;
  }
  h2 {
    font-size: clamp(20px, 1.8rem, 150px) !important;
  }
  h3 {
    font-size: clamp(15px, 1.3rem, 140px) !important;
  }
  .lrg_txt {
    font-size: clamp(15px, 1rem, 140px) !important;
    font-weight: 600;
  }
  .ext_lrg_txt {
    font-size: clamp(15px, 2rem, 140px) !important;
    font-weight: 600;
  }
  .bg_blu_grad {
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 20px;
  }
  .bg_blu_grad:not(:last-child) {
    margin-bottom: 10px;
  }
  .bg_blu_grad:last-child {
    width: 100%;
  }
  .ftr_sghn_bx .ftr_inp_fld {
    padding: 13px;
  }
  .sgn_socls {
    width: 80%;
    margin: auto;
  }
  .hom_bnr.cu_h-100 {
    height: 72%;
  }
  .ftr_nv li {
    width: 50%;
  }
  .pricing_box {
    width: 70%;
    margin: auto;
    margin-top: 0.8rem;
  }

  .cnt_dtl_pic_box {
    flex: 0 1 calc(100% - 20px);
    text-align: center;
  }

  .cu_h-100 {
    height: 80%;
  }

  .terms_tb li a {
    padding: 15px 7px;
  }
}

@media (max-width: 380px) {
  html {
    font-size: 35%;
  }
  h2 {
    font-size: clamp(17px, 1.8rem, 150px) !important;
  }
  h6 {
    font-size: clamp(15px, 2rem, 140px) !important;
  }
  lrg_txt {
    font-size: clamp(15px, 1rem, 140px) !important;
    font-weight: 600;
  }
  body,
  p,
  a,
  div {
    font-size: clamp(12px, 1rem, 50px) !important;
    width: unset;
  }
}
.form-control:focus {
  border-color: #dd1526;
}
.textsec {
  padding: 50px;
}
.trandingbox {
  border: 1px #cecdcd solid;
  border-radius: 5px;
}
.trandinghd {
  width: 100%;
  float: left;
}
.lefttext {
  float: left;
  margin: 10px 0 0 10px;
}
.trandinghd h2 {
  font-size: 16px;
}
.righttext {
  padding: 10px;
  float: right;
  border-radius: 0 5px 0 0;
  background-image: url("../images/blue_dot_bg.png");
  background-color: #111111 !important;
  background-size: cover;  
}
.righttext .MuiTypography-body1{
  color: #fff;
}
.MuiDialog-container .MuiDialog-paperWidthSm{
    border-bottom: 1px solid #cecdcd;
}
.righttext .MuiTypography-body1 span:last-child{
  color: #fff !important;
  padding-left: 4px;
  opacity: 0.7;
}
.centertext {
  padding: 10px;
  display: flex;
  justify-content: center;
}
.trandingdetails {
  padding: 10px;
}
.trandingprice {
  padding: 10px;
}
.trandingprice p:first-child {
  margin-right: 15px;
}
.trandingpage .sldr {
  width: 100%;
  left:5%;
  position: absolute;
  z-index: 99999;
}
.trandingpage .sldr .slick-dots {
  text-align: center;
}
.slick-arrow slick-prev {
  background-color: #06b4e0 !important;
  width: 70px !important;
}
.trandingpage .slick-prev:before {
  opacity: 1 !important;
  color: #000 !important;
}

.trandingpage .slick-prev:before,
.trandingpage .slick-next:before {
  font-size: 30px;
}

.trandingpage .slick-prev {
  left: 40px;
  z-index: 99;
}
.trandingpage .slick-next {
  right: 20px;
  z-index: 99;
}
.trandingpage .slick-next:before {
  opacity: 1 !important;
  color: #000 !important;
}

.MuiDialog-root {
  z-index: 99999 !important;
}
.MuiTypography-h6 {
  padding-top: 18px;
}
.MuiSvgIcon-root {
  margin-top: 18px;
}
#dropdown-basic-button {
  background: none;
  border: none;
  font-size: 1.2rem;
  color: black;
}
#dropdown-basic-button:focus {
  box-shadow: none;
}
.orangebtn{border:none; padding:12px 20px; border-radius:5px;
  background: #fb9314; /* Old browsers */
  background: -moz-linear-gradient(left, #fb9314 0%, #e73e1e 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #fb9314 0%,#e73e1e 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #fb9314 0%,#e73e1e 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fb9314', endColorstr='#e73e1e',GradientType=1 ); /* IE6-9 */}
  
  
  /*---------------------------------------------------
  ------------------------------------------------------
  Dashboard Page
  ----------------------------------------------------------*/
  /* .textsec{background-color: #000;background:url(../images/bg.png) no-repeat ; background-size:100%; background-position:top;} */
  
  .textbox{background:#fff; border:1px solid #d0d0d0; float:left; width:100%; padding:30px; margin-bottom:30px; min-height:300px; border-radius:5px;}
  .textbox button{color:#fff; padding:10px 30px; float:right}
  .textbox h3{float:left;}
  .textblock{margin:0 0 10px 0; display:inline-block;}
  .textblock h3{margin-top:0px; font-size:20px;}
  .textblock p{font-family: 'caviar_dreamsregular'; font-size:16px; color:#545454;}
  .textblock p a{color:#e51912}
  .zgrd_btn{ background: #dd1526;}
  .zgrd_btn:hover{
    background-image: linear-gradient(
      to left,
      #fa9014,
      #f77e13,
      #f26b15,
      #ed5719,
      #e7401e
    ); color: #dd1526;}
  
.tophd{background:#000; position:relative; width:100%; float:left; z-index:9; padding:5px 0;}
.tophd ul{float:right; margin:0; padding:0}
.tophd ul li{display:inline-block; list-style:none; margin:0px; padding:0px 8px;}
.tophd ul li a{color:#3095ce; font-weight:600;}
 
.fxd{padding-top:20px;}
  
  
  
  
  
/*---------------------------------------------------
------------------------------------------------------
FORM SECTION
----------------------------------------------------------*/
.menulist{}
.menulist ul{text-align:center;}
.menulist ul li{display:inline-block; font-weight:bold; padding:0 15px;}
.menulist ul li a{color:#de1424;  border-bottom:2px solid transparent; font-size:22px;}
.menulist ul li a.active{border-bottom:2px #de1424 solid; color:#3095ce}
.menulist ul li a:hover{border-bottom:2px #de1424 solid; color:#3095ce}
.general_frm input[type="text"] , .general_frm input[type="email"] , .general_frm input[type="password"]{border:1px #bebebe solid; background:#fff; width:100%; color:#323232; font-size:16px; padding:10px; border-radius:5px;}
.general_frm input[type="submit"]{border:none; border-radius:5px; width:100%; color:#fff; font-weight:bold; padding:12px 0;
background-image: linear-gradient(
    to right,
    #fa9014,
    #f77e13,
    #f26b15,
    #ed5719,
    #e7401e
  );}
.general_frm input[type="submit"]:hover {
  text-decoration: none;
  color: #3095ce !important;
  background-image: linear-gradient(
    to left,
    #fa9014,
    #f77e13,
    #f26b15,
    #ed5719,
    #e7401e
  );
  transition: all 0.8s ease-in-out;
}
.dashboard-textsec{
  padding: 80px 0;
}
.MuiListItem-container{
  padding-bottom: 5px;
}

.MuiListItemSecondaryAction-root{
  top: 29% !important;
}
.MuiIconButton-root:hover{
  background-color: transparent !important;
}
button.MuiIconButton-root:focus{outline: none;}

.text-capitalize{
color:#dd1526 !important ;
}
.mx-auto{
  color: #dd1526;
}
/*.img-fluid, .img-thumbnail {
  width: 100% !important;
}*/
.noteContainer{
  padding: 50px;
}
.socl img{width:48px;}

.bg2_pic_left_right {
  background-image: url(../images/bg_pic_r.png), url(../images/bg_pic_l.png);
  background-position: right center, left center;
  background-repeat: no-repeat;
  background-size: contain;
}
.cnt_dtl_pic_box:hover{
  color: #dd1526 !important;
}
.bg_pic_left:hover{
  color: #dd1526 !important;
}
.pricing_box p {
  color: #fff;
}
.headerimg{
  width: 100%;
}

.tab-content>.active{
  color: red;
}
.flip-card {
  background-color: transparent;
  width: 250px;
  height: 150px;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: #bbb;
  color: black;
}

.flip-card-back {
  color: #000 !important;
  background-image: linear-gradient(
    to right,
    #fa9014,
    #f77e13,
    #f26b15,
    #ed5719,
    #e7401e
  );
  
  color: white;
  transform: rotateY(180deg);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  color: red;
}

.dropdown-menu.show {
   position: absolute;
    inset: 0px auto auto 0px;
    margin: 0px;
    transform: translate(-25px, 42px);
    padding: 10px;
}
.dropdown-menu.show a.dropdown-item {
  margin: 8px auto;
}

.noHover{
  pointer-events: none;
}

@media(hover: hover) and (pointer: fine) {
  .flip-card {
    background-color: transparent;
    width: 250px;
    height: 150px;
    perspective: 1000px;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  .flip-card-back {
    color: #000 !important;
    background-image: linear-gradient(
      to right,
      #fa9014,
      #f77e13,
      #f26b15,
      #ed5719,
      #e7401e
    );
    
    color: white;
    transform: rotateY(180deg);
  }

}
