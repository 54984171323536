.sunburst-container {
  
    width: 100%;
  
    height: 100%;
  
    display: flex;
  
    align-items: center;
  
    justify-content: center;
  

}

.sunburst-App {

  width: clamp(20rem, 80%, 80rem);
}

.sunbursts {
  --min: 30rem;
  --gap: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, var(--min)), 1fr));
  gap: var(--gap);
}

.sunburst {
  --color-blue-dark: #123a6b;
  --color-blue-light: #3983de;
  --color-cyan-dark: #005f87;
  --color-cyan-light: #21bfff;
  line-height: 1.4;
  font-weight: 400;
}

.sunburst>svg {
  display: block;
}

.sunburst .title {
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  color: #fff;
}

.sunburst .cell {
  cursor: pointer;
  transition: opacity 0.15s ease;
}

.sunburst .is-highlighting .cell:not(.is-highlighted) {
  opacity: 0.5;
}

.sunburst .tip {
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  text-align: center;
  background-color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.15s ease, opacity 0.15s ease;
}

.sunburst .tip.is-visible {
  visibility: visible;
  opacity: 1;
  padding: 0!important;
}

.sunburst .tip__key {
  font-weight: 700;
}

.sunburst .tip__value {
  opacity: 0.75;
}