@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body
{
    font-family: 'Telegraf';
    margin: 0;
}
.left-c {
    height: 400px;
    overflow-y: scroll;
    padding-right: 20px;
}
.col-left{
  position: relative;
}
.dropdown-new {
    float: left;
    overflow: hidden;
  }

.tabulator, .tabulator .tabulator-tableHolder {
  overflow: hidden !important;
}
  
.dropdown-new .dropbtn-new {
font-size: 17px;    
border: none;
outline: none;
padding: 14px 16px;
background-color: inherit;
font-family: inherit;
margin: 0;
}
  
.dropdown-content-new {
display: none;
position: absolute;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 1;
background: black;
justify-content: center;
}
  
.dropdown-content-new a {
float: none;
color: black ;
padding: 12px 16px !important;
text-decoration: none;
display: block;
text-align: left;
justify-content: center;
}

.nav-newbar-item s-in a:hover, .dropdown-new:hover .dropbtn-new {
display: block;
}
  
.dropdown-content-new a:hover {
color: black;
}
.investement-tabs .nav {
  align-items: center;
}

.investement-tabs-option {
    font-size:11px ;
    margin: 6px ;
    font-weight:normal;
    padding: 0 0.5rem;
}

.inactiveClass {
    color: #c5c5c5!important;
}

.investement-tabs .activeClass{
  color: #fff !important;
  background:#043c8f;
}

.tabulator-row {
    position: relative;
    box-sizing: border-box;
    min-height: 30px;
    border-bottom: 0px !important;
    color: grey !important;
    background-color: whitesmoke !important;
}
.tabulator .tabulator-header .tabulator-frozen.tabulator-frozen-right {
    border-left: 0px !important
}
.tabulator-row .tabulator-frozen.tabulator-frozen-right {
   border-left: 0px !important
}
.MuiPaper-elevation1 {
     box-shadow: 0px !important
}
.tabulator-header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    border-bottom: 0px !important;
    background-color: #e7e7e76b !important;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
}
.MuiPaper-elevation8 {
    box-shadow: 0px !important;
}
.col-left:after {
    content: '';
    width: 1px;
    height: 100%;
    background: #999999;
    position: absolute;
    top: 0;
    right: 4px;
    z-index: 0;
}
.left-c::-webkit-scrollbar {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 0%);
    /*background-color: #f3f3f3;*/
    width: 9px;
}
.left-c::-webkit-scrollbar-track {
    background-color: #f5f5f500;
   
}
.left-c::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #999999;
    width: 5px;
    z-index: 9999999;
}

.custom-select-hover {
    text-decoration:underline;
}

.custom-select-wrapper {
  position: relative;
  display: inline-block;
  user-select: none;
  width: 10%;
  margin-bottom: 20px;
}
  .custom-select-wrapper select {
    display: none;
  }
  .custom-select {
    position: relative;
    display: inline-block;
  }
    .custom-select-trigger {
    position: relative;
    display: block;
    width: auto;
    padding: 0px 100px 0 10px;
    border-radius: 10px!important;
    font-size: 14px;
    border: 1px solid #d3d3d3;
    font-weight: 300;
    color: #393939;
    font-weight: 600;
    line-height: 33px;
    background: #fff;
    text-align: left;
    border-radius: 4px;
    cursor: pointer;
}
   .custom-select-trigger:after {
    position: absolute;
    display: block;
    content: '';
    width: 4px;
    height: 4px;
    top: 50%;
    right: 17px;
    margin-top: -2px;
    border-bottom: 2px solid #777777;
    border-right: 2px solid #777777;
    transform: rotate( 
45deg
 ) translateY(-50%);
    transition: all .4s ease-in-out;
    transform-origin:50% 0;
}
     .custom-select.opened .custom-select-trigger:after {
    margin-top: 0px;
    transform: rotate(-135deg) translateY(-50%);
}
  .custom-options {
    position: absolute;
    display: block;
    top: 100%; left: 0; right: 0;
    min-width: 100%;
    margin: 15px 0;
    border: 1px solid #b5b5b5;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 2px 1px rgba(0,0,0,.07);
    background: #fff;
    transition: all .4s ease-in-out;
    
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  .slection-style label {
    display: flex;
    color: #ababab;
    font-size: 11px;
    font-weight: 700;
    margin-bottom: 3px;
    text-transform: uppercase;
    letter-spacing: 1px;
}
span.custom-select-trigger:before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #c7c7c7;
    border-radius: 100%;
    right: 9px;
    top: 5px;
}
  .slection-style {
    max-width: 1302px;
    margin: 0 auto;
    top: 25px;
}
  .custom-select.opened .custom-options {
    opacity: 1;
    visibility: visible;
    z-index: 99999;
    pointer-events: all;
    transform: translateY(0);
}
    .custom-options:before {
    position: absolute;
    display: block;
    content: '';
    bottom: 100%;
    right: 25px;
    width: 7px;
    height: 7px;
    margin-bottom: -4px;
    border-top: 1px solid #434343;
    border-left: 1px solid #434343;
    background: #414141;
    transform: rotate(45deg);
    transition: all .4s ease-in-out;
}
    .option-hover:before {
      text-decoration:underline;
    }
    .custom-option {
    position: relative;
    background: #414141;
    display: block;
    text-align: center;
    border-bottom: 1px solid #b5b5b5;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    cursor: pointer;
    transition: all .4s ease-in-out;
}
    .custom-option:first-of-type {
      border-radius: 4px 4px 0 0;
    }
    .custom-option:last-of-type {
      border-bottom: 0;
      border-radius: 0 0 4px 4px;
    }
    .custom-option:hover,
    .custom-option.selection {
      text-decoration:underline;
      opacity: 1;
    }
.tabulator-row .tabulator-cell.tabulator-row-handle .tabulator-row-handle-box .tabulator-row-handle-bar {
    width: 100%; 
    height: 0px;
    margin-top: 0px;
    background: 0px !important;
}
li.nav-newbar-item.s-in button.dropbtn-new {
  font-size: 11px;
  font-weight: 700;
  color: #fff;
}
li.nav-newbar-item.s-in i {
  margin-left: 3px;
}
li.nav-newbar-item.s-in a {
  cursor: pointer;
}
li.nav-newbar-item a:hover, li.nav-newbar-item button:hover{
  opacity: 0.5 !important;
}
li.nav-newbar-item button:hover{
  color: #c5c5c5 !important;
}
a.nav-newbar-link.activeClassHeader {
  opacity: 0.5 !important;
}
ul.nav-newbar-links a {
  color: #c5c5c5!important;
  font-weight: 700;
  padding: 6px;
  font-size: 11px;
}
div#nav-newbar-menu {
    width: 70%;
    color: gray
}
header#nav-newbar {
    background: #f4f5f7;
    padding: 10px 0;
    box-shadow: none;
    z-index: 88888;
}

li.nav-newbar-item.socials {
    text-align: center;
}
#nav-newbar {
  --nav-newbar-height: 96px;
  position: fixed;
  height: var(--nav-newbar-height);
  background-color: var(--nav-newbar-bg-color);
  left: 0;
  right: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
}
.img-logo {
   height:auto;
   width:50%;
}
.investement {
    display: block;
    padding-top: 84px;
}
.invest-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 650px;
  margin: 108px auto 0 auto;
}
.invest-content p {
    text-align: center;
}
.invest-content span {
  font-size: 12px;
  font-weight: 600;
  color: #737373;
}
ul.nav-new.nav-new-tabs {
    clear: both;
}
ul.nav-new.nav-new-tabs li {
    list-style: none;
    float: left;
}   
.nav-new>li>a {
    position: relative;
    display: block;
    padding: 8px 8px;
}
.nav-new {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    cursor: pointer
}     
.investement-tabs .root-inv {
    max-width: 1590px;
    margin: 0 auto;
    padding: 0 30px;
  }
.main-col {
    width: 100%;
    margin: 25px auto;
    display: flex;
    justify-content: space-between;
}
.table.IBSBVL {
    padding: 0;
    border: 1px solid rgb(255 255 255 / 5%);
    border-radius:4px;
    /*background: white;*/
}
.table.IBSBVL h4 {
    margin: 0 auto;
}
.col-right {
    width: 40%;
    margin: 0 auto;
    border-top: 1px solid rgb(255 255 255 / 5%);
    border-bottom: 1px solid rgb(255 255 255 / 5%);
    padding-bottom: 50px;
}
.col-right .tabulator .tabulator-header{
    background:inherit !important; 
}
.col-right .tabulator{
    background:inherit !important; 
}
/*.col-right .makeStyles-root-3 .MuiPaper-root{
    border:0 !important; 
}*/
.col-right .tabulator-row{
    background:inherit !important; 
}
.col-right .tabulator .tabulator-header .tabulator-col{
    background:inherit !important; 
}
.col-right .tabulator-tableHolder{
    background:inherit !important; 
}
.col-right .tabulator-col-title {
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
}
.col-right .tabulator .tabulator-tableHolder .tabulator-table{
    background:inherit !important; 
}
.col-right .tabulator-row .tabulator-cell{
     color:#ffff; 
     border-bottom: 1px solid rgb(255 255 255 / 5%);
}
.col-right .MuiBox-root-6:hover, 
.col-right .MuiBox-root .MuiTypography-root + .MuiBox-root .MuiBox-root:hover{
  background: #043c8f !important;
}
.col-right .MuiBox-root-6, .col-right .MuiBox-root .MuiTypography-root + .MuiBox-root .MuiBox-root{
      padding: 13px 16px !important;
      text-align: center;
      color: white;
      font-weight: 400;
      line-height: 100%;
      text-decoration: none;
      border: 2px solid #043c8f !important;
      margin: 0 0 0 11px;
      background: inherit !important;
      border-radius: 0 !important;
      color: #fff;
      cursor: pointer;
}
.main-col .col-right .MuiBox-root-6 div,
.col-right .MuiBox-root .MuiTypography-root + .MuiBox-root .MuiBox-root div{
      font-size: 14px !important;
}
.main-col .col-right .MuiBox-root-7 div,
.col-right .MuiBox-root .MuiTypography-root + .MuiBox-root .MuiBox-root:nth-child(2):hover div{
      font-size: 14px !important;
}
.col-right .MuiBox-root-7:hover,
.col-right .MuiBox-root .MuiTypography-root + .MuiBox-root .MuiBox-root:nth-child(2):hover{
      border: 2px solid #043c8f !important;
      background: inherit !important;
}
.col-right .MuiBox-root-7, .col-right .MuiBox-root .MuiTypography-root + .MuiBox-root .MuiBox-root:nth-child(2){
  padding: 13px 16px !important;
  text-align: center;
  color: white;
  font-weight: 400;
  line-height: 100%;
  text-decoration: none;
  background: #043c8f !important;
  margin: 0 0 0 11px;
  color: #fff !important;
  border-radius: 0 !important;
  border: 2px solid transparent !important;
  cursor: pointer;
}
.col-right .tabulator-row.tabulator-selectable:hover {
  background-color: #232b36!important;
}
td {
    color: #5d5d5d;
    font-size: 14px;
}
.accordion {
    padding: 0px 0;
   
    border-radius: 20px;
    width: 100%;
    margin: 6px auto 0px;
    display: block;
}

.accordion__title {
  margin-bottom: 1rem;
  padding-left: 1.2rem;
}
.accordion__item:not(:last-child) {
  border-bottom: 1px solid lightgrey;
}

.accordion__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.2rem;
  border-radius: 15px;
  background: white;
  border: none;
  outline: none;
  color: var(--color-text);
  font-size: 1.2rem;
  text-align: left;
  cursor: pointer;
  transition: .1s;
}
.bac-d a.backf {
    background: #ff592c;
    color: white;
    padding: 10px 30px;
    border-radius: 20px;
    margin-right: 10px;
}
.bac-d a.downl {
    background: #ededed;
    color: #171717;
    padding: 10px 30px;
    border-radius: 20px;
    border: 1px solid #afafaf!important;
}
.bac-d {
    display: flex;
    margin: 20px auto;
    justify-content: right;
}
.col-right table {
    padding: 20px;
    border: 1px solid #b5b5b5;
    background: #e7e7e76b;
    border-radius: 20px;
}
.accordion__btn:hover {
  color: var(--color-purple);
  background: hsl(248, 53%, 95%);
}



.accordion__icon {
  border-radius: 50%;
  transform: rotate(0deg);
  transition: .3s ease-in-out;
  opacity: .9;
}
.accordion__item--active .accordion__icon {
  transform: rotate(90deg);
}

.accordion__content {
  font-weight: 300;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
   color: var(--color-text-muted);
  transform: translateX(16px);
  transition: max-height .2s ease-out, opacity 0.3s ease-in, transform .4s ease;
}
.accordion__content p {
  padding: 1rem 1.2rem
}

.accordion__item--active .accordion__content {
  opacity: 1;
  transform: translateX(0px);
}
table th {
    font-size: 12px;
    text-align: left;
}
.table.IBSBVL p {
    margin: 8px auto;
    font-size: 15px;
    color: #5f5f5f;
    border-bottom: 1px solid #dddddd;
    padding-bottom: 10px;
}

  .nav-new-tabs>li>a {
    margin-right: 2px;
    line-height: 1.42857143;
    border: 1px solid transparent;
    border-radius: 4px 4px 0 0;
}
.nav-new-tabs>li.active>a, .nav-new-tabs>li.active>a:focus, .nav-new-tabs>li.active>a:hover {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent;
}
.investement-tabs .root-inv .col-left {
    width: 60%;
    border-right: 1px solid rgb(255 255 255 / 5%);
    border-top: 1px solid rgb(255 255 255 / 5%);
    border-bottom: 1px solid rgb(255 255 255 / 5%);
    padding-top: 20px;
    padding-bottom: 40px;
}
.investement-tabs ul.nav-new.nav-new-tabs {
    border: none;
}
.investement-tabs a {
    border: none!important;
}
.investement-tabs li.active a {
    background: #414141!Important;
    border-radius: 50px!important;
    color: white!important;
}
ul.nav-new.nav-new-tabs {
    display: flex;
    justify-content: center;
}
.nav-new>li {
    position: relative;
    display: block;
}
.investement-tabs a {
    font-size: 12px;
    color: #fff;
    text-decoration: none;
    font-weight: 600;
}
.nav-new-tabs>li {
    float: left;
    margin-bottom: -1px;
}
.nav-new:after, .nav-new:before, .nav-newbar-collapse:after, .nav-newbar-collapse:before, .nav-newbar-header:after, .nav-newbar-header:before, .nav-newbar:after, .nav-newbar:before, .pager:after, .pager:before, .panel-body:after, .panel-body:before, .row:after, .row:before {
    display: table;
    content: " ";
}
.nav-new:after, .nav-newbar-collapse:after, .nav-newbar-header:after, .nav-newbar:after, .pager:after, .panel-body:after, .row:after {
    clear: both;
}   
.nav-new-tabs {
    border-bottom: 1px solid #ddd;
} 
.tab-pane:not(.active) {
    display: none;
}    
.investement-tabs .inactiveClass, .investement-tabs activeClass {
  cursor: pointer;
}
.tabulator-tableHolder {
  background: whitesmoke;
}
.makeStyles-root-3 .MuiPaper-root {
  border: 1px solid #e1e2e4!important;;
  background: inherit;
  border-radius: 20px;
  box-shadow: none!important;
}
.MuiAccordionSummary-root {
  padding: 8px 16px!important;
}
.MuiAccordionSummary-expandIcon.Mui-expanded {
  transform: rotate(90deg)!important;
}
.MuiButtonBase-root svg.MuiSvgIcon-root {
  margin: 0;
  width: 20px;
    height: 20px;
    border-radius: 50%;
    transform: rotate(269deg);
border: 1px solid #e4e4e4;
}
div#included_service .MuiAccordion-root:before
{
  display:none;
}
.MuiPaper-elevation1 {
  margin: 12px auto;
  box-shadow: none!important;
}
.makeStyles-heading-2 {
  color: #878a91!important;
  font-size: 14px!important;
  font-family: 'Montserrat'!important;
  font-weight: 500!important;
}
.tabulator .tabulator-tableHolder {
 overflow: hidden;
}
.nav-newbar-container {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    max-width: 1300px;
    margin: 0 auto;
}
.nav-newbar-item {
    margin: 0 auto;
    width: fit-content;
}
.home-link,
.nav-newbar-link {
  color: var(--nav-newbar-text-color);
  text-decoration: none;
  display: flex;
  font-weight: 400;
  align-items: center;
}

.home-link:is(:focus, :hover) {
  color: var(--nav-newbar-text-color-focus);
}

.nav-newbar-link {
  justify-content: center;
  width: 100%;
  padding: 0.4em 0.8em;
  border-radius: 5px;
}

.nav-newbar-link:is(:focus, :hover) {
  color: var(--nav-newbar-text-color-focus);
  background-color: var(--nav-newbar-bg-contrast);
}
.nav-newbar-logo {
  background-color: var(--nav-newbar-text-color-focus);
  border-radius: 50%;
  margin-right: 0.5em;
}
#nav-newbar-toggle {
  cursor: pointer;
  border: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.icon-bar {
    display: block;
    background: white;
    width: 25px;
    height: 4px;
    margin: 2px;
}
#nav-newbar-toggle:is(:focus, :hover) .icon-bar {
  background-color: white;
}
#nav-newbar-toggle[aria-expanded="true"] .icon-bar:is(:first-child, :last-child) {
  position: absolute;
  margin: 0;
  width: 30px;
}
#nav-newbar-toggle[aria-expanded="true"] .icon-bar:first-child {
  transform: rotate(45deg);
}
#nav-newbar-toggle[aria-expanded="true"] .icon-bar:nth-child(2) {
  opacity: 0;
}
#nav-newbar-toggle[aria-expanded="true"] .icon-bar:last-child {
  transform: rotate(-45deg);
}
#nav-newbar-menu {
  position: fixed;
  top: var(--nav-newbar-height);
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  left: 0;
  right: 0;
}
#nav-newbar-toggle[aria-expanded="true"] + #nav-newbar-menu {

  opacity: 1;
  visibility: visible;
}
.activeClassHeader {
  font-weight: bold !important
}
.nav-newbar-links {
  list-style: none;
  position: absolute;
 
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 0;
  right: 0;
  margin: 1.4rem;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
li.nav-newbar-item.s-in {
    position: relative;
}

li.nav-newbar-item.s-in a {
    color: #4e4e4e;
    text-decoration: none;
    border: none;
    padding: 4px 8px;
    border-radius: 15px;
}
li.nav-newbar-item.s-in li a {
    color: #4e4e4e;
    text-decoration: none;
    padding: 4px 20px;
    border:0px;
    border-radius: 0px;
}

li.nav-newbar-item.socials i {
    color: #4e4e4e;
    padding: 4px;
}
.tab-content {
    background: #f3f3f3;
    padding: 3rem 0;
    margin: 2rem auto 0;
}
.thrt a {
    color: #818181;
    text-decoration: none;
    font-weight: 600;
    padding: 4px;
    display: block;
}
#nav-newbar-toggle[aria-expanded="true"] + #nav-newbar-menu .nav-newbar-links {
    padding: 2em 0 2.5rem;
}
.footer-div {
    display: flex;
    background: #fcfcfc;
    border-bottom: 1px solid #efefef;
    justify-content: space-evenly;
    border-top: 1px solid #efefef;
    margin: 1rem auto 0;
    max-width: 1300px;
}
.copyright {
    display: flex;
    justify-content: space-between;
    max-width: 1230px;
    margin: 0 auto;
    font-size: 14px;
    padding: 6px;
    color: #979797;
}
.thrt ul {
    padding: 0;
    list-style: none;
    color: #818181;
    font-size: 14px;
    font-weight: 500;
}
.twen,.thrt {
    padding: 25px 0;
    width: 20%;
    border-right: 1px solid #efefef;
}
.thrt.contact h4 {
    color: #5b5b5b;
    margin-bottom: 0;
}
.thrt.contact p {
    font-size: 14px;
}
.thrt.contact.last {
    border: none;
}
.newslt {
    width: 100%;
    position: relative;
    display: flex;
}
.newslt input[type="email"] {
    border: 1px solid #c1c1c1;
    padding: 12px 45px 12px 12px;
    border-radius: 20px;
    width: 100%;
    font-size: 14px;
}
.newslt a {
    position: absolute;
    right: 0;
    top: 0px;
}
.newslt a i.fa.fa-long-arrow-right {
    color: white;
    width: 33px;
    background: #ff592c;
    height: 33px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.social-icons a {
    margin: 0 10px;
}
.social-icons a i {
    color: #898989;
    font-size: 12px;
}
.thrt ul {
  display: grid;
  grid-template-columns: auto auto;
}
.thrt.contact h4 {
  margin-bottom: 24px;
  font-size: 14px;
}
.newslt form {
  border: none!important;
}
button.btn.grd_btn-new{
  position: absolute;
  right: 20px;
  top: 4px;
  border-radius: 50%;
  width: 26px!important;
  padding: 0;
  height: 26px;
  background: orangered;
}
.newslt input#email {
  padding:6px 0.75rem;
  border-radius: 20px;
}
input#email::placeholder {
  color: #6d6d6d;
  font-size: 13px;
}
.newslt i {
  color: white;
  font-size: 18px;
  position: relative;
  top: -2px;
  left: 1px;
}
.footer-div a, p, h2, h3, h4 {
  font-size: 11px;
}
.copyr {
  font-size: 12px;
}
.MuiBox-root.MuiBox-root-6 div , .MuiBox-root.MuiBox-root-7 div {
  font-size: 12px!important;
  font-weight: 500;
}
/*footer img {
  width: 36%!important;
}*/
.invest-content h2 {
  font-size: 45px!important;
  margin: 15px auto 13px;
  color: #393939;
}
.inverstment-dropdown-tabs .inverstment-dropdown-mobile{
  display: none;
}
.tab-content .tab-pane .main-col .col-left.on-mobile{
  display: none;
}
.tab-content .tab-pane .main-col .col-price-details.for-mobile{
  display: none;
}
@media screen and (min-width: 700px) {

  #nav-newbar-toggle,
  #nav-newbar-toggle[aria-expanded="true"] {
    display: none;
  }

  #nav-newbar-menu,
  #nav-newbar-toggle[aria-expanded="true"] #nav-newbar-menu {
    visibility: visible;
    opacity: 1;
    position: static;
    display: block;
    height: 100%;
  }

  .nav-newbar-links,
  #nav-newbar-toggle[aria-expanded="true"] #nav-newbar-menu .nav-newbar-links {
    margin: 0;
    padding: 0;
    box-shadow: none;
    position: static;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 767px) {
  input#email::placeholder {
    position: relative;
    top: 2px;
}
  .twen {
    text-align: center;
}
  /*footer img {
  width: 26%!important;
}*/
  .newslt i {
    
    top: 3px;
    left: 1px;
}

.twen, .thrt {
  padding: 50px 0;
  width: 100%;
  border: none!important
}
.copyright {
  margin: 5px auto 10px;
}
/*footer {
  background: #fcfcfc;
}*/
  .invest-content {
    margin: 46px auto 10px auto;
}
.invest-content span, .invest-content p {
  font-size: 12px!important;
}
  .table.IBSBVL {
    padding: 14px;  
    overflow: hidden;
}

.table.IBSBVL table {
  overflow: auto;
  width: 100%;
  display: block;
}
._hj_feedback_container ._hj-qoDG___EmotionIconDefault__iconEmotionDefault ._hj-3obO5__EmotionIconDefault__commentIcon:before {
  font-size: 30px;
}
._hj-3J5sV__MinimizedWidgetBottom__open>div:before {

  bottom: 15px!important;
  left: 21px!important;
  box-shadow: 0 2px 18px 18px rgb(0 0 0 / 28%)!important;

}
._hj_feedback_container ._hj-qoDG___EmotionIconDefault__iconEmotionDefault._hj-1BEBR__EmotionIconDefault__like ._hj-2RA7u__EmotionIconDefault__expressionIcon:before {
  
    font-size: 26px;
}
  .newslt {
    display: block;  
  }
  .newslt input#email {
    height: 35px!important;
    padding: 6px 2.75rem!important;
}

.slection-style .custom-select-wrapper {
  width: 32%!important;
}
.slection-style .custom-select {
  height: unset !important;

  padding: 8px!important;
  font-size: 2rem!important;

}
.left-c {
  padding-right: 10px;
}
  .thrt ul {
    display: grid;
    grid-template-columns: auto;
}
  .footer-div {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
}
.newslt {
  display: block;
  margin-top: 20px;
}
button.btn.grd_btn {

  right: 5px;
}
.MuiBox-root-4 {
  flex-direction: column;
  
}
.copyr {
    margin-bottom: 12px;
    margin-top: 4px;
}
.copyright {
    flex-direction: column;
    text-align: center;
}
.nav-newbar-links {
  background: black;
}
.twen, .thrt {
    padding: 25px 0;
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #efefef;
}
.thrt.contact.last {
    margin-bottom: 1rem;
}
.twen, .thrt {
    padding: 25px 0;
    width: 100%;
    border-right: 1px solid #efefef;
}
  header#nav-newbar {
    background: #f4f5f7;
    padding: 0 10px 0;
}
  .investement {
    display: block;
    padding-top: 84px;
    padding: 100px 24px 10px;
    text-align: center;
}
.main-col {
    width: 95%;
    flex-direction: column;
    margin: 0 auto;
}
.investement-tabs .root-inv .col-left {
    width: 100%;
}
.col-right {
    width: 100%;
    margin: 2rem auto;
}
.invest-content {  
   
    width: 100%;

}
ul.nav-new.nav-new-tabs {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 15px;
}
ul.nav-new.nav-new-tabs a {
    width: 50%;
    text-align: center;
    margin: 0 auto;
}
.slection-style {
    position: relative;
    max-width: 86%;
    margin: 0 auto;
    top: 0px;
    left: 0px;
}
li.nav-newbar-item.socials {
    margin-bottom: 14px;
}
.nav-newbar-item {
    margin: 0 auto;
    width: auto;
}
div#nav-newbar-menu ul.nav-newbar-links a {
    padding: 6px 0;
    font-size: 18px;
    margin: 5px auto;
}
  div#nav-newbar-menu {
    width: 100%;
}
li.nav-newbar-item.s-in a {
  text-align: center;
  padding: 5px 29px!important;
}
.nav-newbar-item {
    text-align: center;
}
.main-inv-section p,.main-inv-section span, .main-inv-section a{
  font-size: 12px!important;
}
.invest-content h2 {
  font-size: 25px!important;

}
.main-inv-section .tab-content {
    background: #f3f3f3;
    padding: 1rem;
    margin: 2rem auto 0;
}
.dropdown-item {
    display: block;
    width: 50%;
    text-align: center;
}

ul.nav-newbar-links {
    overflow-y: scroll;
}
}

@media screen and (max-width: 600px) {
    .nav-newbar-item s-in a:not(:first-child), .dropdown-new .dropbtn-new {
      display: none;
    }
    .nav-newbar-item s-in a.icon {
      float: right;
      display: block;
    }
    .dropdown-content-new {
     
      position: relative !important;
      z-index: 1;
      left: 0px;
      background: black;
      text-align: center;
      justify-content: center;
     }
  }
  
  @media screen and (max-width: 600px) {
    .nav-newbar-item s-in.responsive {position: relative;}
    .nav-newbar-item s-in.responsive .icon {
      position: absolute;
      right: 0;
      top: 0;
    }
    .nav-newbar-item s-in.responsive a {
      float: none;
      display: block;
      text-align: left;
    }
    .nav-newbar-item s-in.responsive .dropdown-new {float: none;}
    .nav-newbar-item s-in.responsive .dropdown-content-new {position: relative;}
    .nav-newbar-item s-in.responsive .dropdown-new .dropbtn-new {
      display: block;
      width: 100%;
      text-align: left;
    }
    button.btn.grd_btn-new{
      position: absolute;
      right: 7px;
      top: 4px;
      border-radius: 50%;
      width: 26px!important;
      padding: 0;
      height: 26px;
      background: orangered;
    }
    .dropdown-content-new {
     
      position: relative !important;
      z-index: 1;
      left: 0px;
      background: black;
      text-align: center;
      justify-content: center;
     }
     li.nav-newbar-item.s-in button.dropbtn-new {
      margin: 0 auto;
  }
  }

/* ----------- iPad 1, 2, Mini and Air ----------- */
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1023px) 
  and (-webkit-min-device-pixel-ratio: 1) {
    button.btn.grd_btn-new {
      position: absolute;
      right: 15px;
      top: 2px!important;
      border-radius: 50%;
      width: 20px!important;
      padding: 0;
      height: 20px!important;
      background: orangered;
  }
    .left-c {
      
      padding-right: 25px;
      padding-left: 20px;
  }
    
  a.home-link {
    width: 15%;
}
div#nav-newbar-menu {
  width: 90%;
}
.dropdown-new .dropbtn-new {
  font-size: 14px;
}
.dropdown-content-new {

  min-width: 130px;
  
  left: -20px;
  
}
li.nav-newbar-item a {
  font-size: 12x!important;
}
li.nav-newbar-item.socials {
  text-align: center;
  display: flex;
}
ul.nav-newbar-links a {
  padding: 4px!important;

}
li.nav-newbar-item.socials a {
  padding: 2px;
}
.img-logo {
  height: auto;
  width: 58%;
}
}

.menu-a a, .menu-b a {
  cursor: pointer;
}

.grid_section_component .tab-content {
  background:unset;
}

/* latestFooter */
/*============= footer =============*/
footer {
  font-family: 'PP Telegraf';
  font-style: normal;

}
footer .footer_col img {
  height: 60px;
  width: auto;
  margin-bottom: 20px;
}
footer .footer_col p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.6);
  padding-right: 27px;
}
footer .footer_col h2 {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  color: #FFFFFF;
  margin-bottom: 35px !important;
}
footer .footer_col .block_box {
  margin-bottom: 30px;
}
footer .footer_col .block_box:last-child {
  margin: 0;
}
footer .footer_col .block_box h3 {
  font-weight: 400;
  font-size: 14px !important;
  line-height: 100%;
  letter-spacing: 0.2px;
  color: #FFFFFF;
}
footer .footer_col .block_box ul {
  padding: 0;
  margin: 0;
}
footer .footer_col .block_box ul.flex_ul_li li {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 11px 0;
}
footer .footer_col .block_box ul.flex_ul_li li i {
  color: #ffffffa8;
  margin-right: 7px;
}
footer .footer_col .block_box ul.flex_ul_li li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-decoration: underline;
  color: rgba(255, 255, 255, 0.6);
}
footer .footer_col .block_box ul li a:hover,
.copyright_box ul li a:hover,
.copyright_box p.last_p a:hover {
  color: #fff !important;
}
footer .footer_col .block_box ul.flex_ul_li li a.no_decor{
  text-decoration: none;
}
footer .footer_col .block_box .footer_links.block_ul_li li {
  display: flex;
  margin: 0 0 27px;
}
footer .footer_col .block_box .footer_links.block_ul_li li:last-child {
  margin: 0;
}
footer .footer_col .block_box .footer_links.block_ul_li li a {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.2px;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
}
.copyright_box {
  border-top: 1px solid rgb(255 255 255 / 8%);
  margin-top: 50px;
  padding: 30px 0;
  font-family: 'PP Telegraf';
  font-style: normal;
}
.copyright_box p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: rgba(255, 255, 255, 0.6);
  line-height: 19px;
}
.copyright_box ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.copyright_box ul li {
  display: inline-flex;
  width: 35px;
  justify-content: center;
}
.copyright_box ul li a {
  display: flex;
  color: rgba(255, 255, 255, 0.6);
  text-decoration: none;
  width: 100%;
}

.copyright_box p.last_p {
  display: flex;
  justify-content: end;
}
.copyright_box p.last_p a {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  text-align: right;
  text-decoration-line: underline;
  color: rgba(255, 255, 255, 0.6);
}
footer .footer_col .card-header{
  border: 0;
  padding: 0;
  background: transparent;
}
footer .footer_col.card{
  border: 0;
  background: transparent;
  text-decoration: none;
  outline: none;
}
footer .footer_col.card .collapse:not(.show){
  display: block;
}
footer .footer_col .card-header .btn{
  pointer-events: none;
}
footer .footer_col .btn{
  padding: 0;
  color: #fff;
  text-decoration: none;
  box-shadow: none;
}
footer .footer_col .btn:hover{
  color: #fff !important;
  text-decoration: none;
}
footer .footer_col.card .card-body{
  padding: 0;
}

/* responsive - footer */


@media screen and (max-width: 767px) and (min-width: 576px){
  footer .footer_col {
      margin-bottom: 30px;
  }
  footer .noborder_tab {
      border-bottom: 0 !important;
      margin-bottom: 0 !important;
  }
}


@media screen and (max-width: 992px) and (min-width: 768px){
  footer .noborder_tab {
      border-bottom: 0 !important;
      margin-bottom: 0 !important;
  }
  footer .col-md-3:last-child {
      flex: 0 0 22%;
      max-width: 22%;
  }
  footer .col-md-3:nth-child(2) {
      flex: 0 0 28%;
      max-width: 28%;
  }
  .copyright_box ul li {
    margin: 0 10px;
    width: 15px;
}
  .copyright_box ul li a{
    font-size: 15px;
  }
}

@media screen and (max-width: 767px){
  footer .footer_col img {
      width: 80px;
      margin-bottom: 20px;
  }
  footer .footer_col .block_box ul.flex_ul_li li a{
    font-size: 10px !important;
  }
  .copyright_box p{
    text-align: left;
  }
  footer .footer_col p{
    padding-right: 0;
  }
  .copyright_box ul li {
      margin: 0 10px;
      width: 15px;
  }
  .copyright_box ul li a{
    font-size: 15px;
  }
  .copyright_box {
      text-align: center;
      border: 0;
  }
  .copyright_box p.last_p {
      display: flex;
      justify-content: center;
  }
  footer .footer_col .footer_accord_hd span.caret_span img {
      width: 10px;
      height: auto;
  }
  footer .footer_col h2{
      margin: 0;
      font-size: 18px;
      line-height: 19px;
  }
  footer .footer_col .footer_accord_hd {
      padding: 15px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  footer #accordion_footer .footer_col {
      border-bottom: 1px solid #ffffff3b;
      min-height: auto;
  }
  footer .footer_col .footer_accord_hd span.caret_span {
      height: 7px;
  }
  footer .footer_col .footer_accord_hd[aria-expanded="true"] .caret_span img {
      transform: rotate(180deg);
  }
  footer #accordion_footer .footer_col .block_box:last-child{
      margin-bottom: 30px;
  }
  footer #accordion_footer .footer_col .block_box{
      padding-top: 17px;
  }
  footer #accordion_footer .footer_col .block_box:last-child {
      padding: 0;
  }
  section.footer-sec .container{
    padding: 0 25px !important;
  }
}
@media screen and (max-width: 575px){
  footer .footer_col.footer-logo p{
    font-size: 19px !important;
    max-width: 380px;
    margin: 0 auto;
  }
  .footer_col.footer-logo{
    text-align: center;
    padding-bottom: 35px;
    padding-top: 50px;
  }
  footer .footer_col .card-header .btn{
    pointer-events: all;
  }
  footer .footer_col img {
    height: auto;
    width: 100%;
    max-width: 156px;
    margin-bottom: 20px;
}
  .noborder_mob {
      border: 0 !important;
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
  }
  footer .footer_col .btn{
    width: 100%;
    text-align: left;
    font-size: 18px !important;
    font-weight: bold;
    outline: none;
    box-shadow: none;
    padding-bottom: 20px;
    border-bottom: 1px solid #ffffff1a;
    position: relative;
  }
  footer .footer_col .btn:after{
    content: '';
    position: absolute;
    right: 10px;
    top: 40%;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 5px 0 5px;
    border-color: #fff transparent transparent transparent;
  }
  footer .footer_col h2{
    font-size: 18px !important;
    font-weight: bold;
    margin-bottom: 30px !important;
  }
  footer .footer_col.card .collapse:not(.show){
    display: none;
  }
  footer .footer_col.card .collapse.show{
    display: block;
    margin-bottom: 30px;
  }
  footer .footer_col .block_box ul.flex_ul_li li a{
    font-size: 14px !important;
  }
  .copyright_box p{
    text-align: center;
    font-size: 20px !important;
    line-height: 24px;
    margin-bottom: 20px !important;
  }
  .copyright_box ul{
    margin-bottom: 20px;
  }
  .copyright_box ul li a{
    font-size: 30px !important;
  }
  .copyright_box{
    margin-top: 0;
    padding-bottom: 60px;
  }
  .copyright_box p.last_p a{
    font-size: 20px !important;
    line-height: 24px;
  }
  footer .footer_col .block_box ul.flex_ul_li li a{
    font-size: 14px !important;
  }
  .inverstment-dropdown-tabs .inverstment-dropdown-mobile{
    display: block;
    text-align: center;
  }
  .inverstment-dropdown-tabs .inverstment-dropdown-desktop{
    display: none;
  }
  .inverstment-dropdown-tabs .inverstment-dropdown-mobile .dropdown-toggle{
    width: 100%;
    padding: 18px 16px;
    max-width: 380px;
    border: 0;
    font-weight: 400;
    font-size: 18px !important;
    text-align: left;
    background: #043c8f !important;
    color: #fff !important;
    letter-spacing: 0.2px;
    box-shadow: none !important;
    position: relative;
    border-radius: 0;
  }
  .inverstment-dropdown-tabs .inverstment-dropdown-mobile .dropdown-toggle:after{
    display: none;
  }
  .inverstment-dropdown-tabs .inverstment-dropdown-mobile .dropdown-toggle:before{
    content: '\f107';
    position: absolute;
    font-size: 25px;
    top: 50%;
    right: 19px;
    color: #fff;
    font-family: 'FontAwesome';
    transform: translateY(-50%);
  }
  .inverstment-dropdown-tabs .inverstment-dropdown-mobile .dropdown-menu{
    width: 100%;
    padding: 0;
    max-width: 380px;
    border: 0;
    font-weight: 400;
    text-align: left;
    background: #043c8f !important;
    color: #fff !important;
    letter-spacing: 0.2px;
    border-radius: 0;
    border-top: 1px solid #fff;
  }
  .inverstment-dropdown-tabs .inverstment-dropdown-mobile .dropdown-menu .investement-tabs-option{
    border-bottom: 1px solid #fff;
    border-radius: 0;
    margin: 0;
    color: #fff !important;
    padding: 12px 16px;
    font-size: 16px; 
  }
  .tab-content .tab-pane .main-col{
    margin: 0;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 15px;
    width: 100%;
  }
  .tab-content .tab-pane .main-col .col-left.on-mobile:after{
    display: none;
  }
  .tab-content .tab-pane .main-col .col-left.on-desktop{
    display: none;
  }
  .tab-content .tab-pane .main-col .col-left.on-mobile{
    display: block;
    border: 0;
    padding: 0;
  }
  .tab-content .tab-pane .main-col .col-price-details ul{
    border: 1px solid #303030;
    border-radius: 6px;
    margin-bottom: 14px;
  }
  .tab-content .tab-pane .main-col .col-price-details ul li{
    padding: 19px 16px;
    border-bottom: 1px solid #303030;
    display: flex;
    align-content: center;
    justify-content: space-between;
  }
  .tab-content .tab-pane .main-col .col-price-details ul li:last-child{
    border-bottom: 0;
  }
  .tab-content .tab-pane .main-col .col-price-details ul li p{
    font-weight: 400;
    font-size: 18px !important;
    letter-spacing: 0.2px;
    color: #858585;
  }
  .tab-content .tab-pane .main-col .col-price-details ul li p span{
    color: #fff;
  }
  .tab-content .tab-pane .main-col .col-price-details ul li p.plus-icon a{
    width: 25px;
    height: 25px;
    background: rgba(255, 255, 255, 0.6);
    color: #000;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
  }
  .tab-content .tab-pane .main-col .col-price-details{
    overflow: hidden;
    overflow-y: auto;
    max-height: 960px;
    padding: 0 10px;
  }
  .tab-content .tab-pane .main-col .col-price-details::-webkit-scrollbar-track {
    border-radius: 15px !important;
    background: #D8D8D8;
    overflow: hidden;
    width: 5px;
  }
  .tab-content .tab-pane .main-col .col-price-details::-webkit-scrollbar {
      width: 5px;
      background: #D8D8D8;
  }
  .tab-content .tab-pane .main-col .col-price-details::-webkit-scrollbar-thumb {
      background: #716F6F;
      border-radius: 15px;
      border-color: #716F6F;
      width: 5px;
  }
  /*.col-right.on-desktop .makeStyles-root-3 .MuiPaper-root{
    display: none;
  }*/
  .tab-content .tab-pane .main-col .col-price-details.for-mobile{
    display: block;
    width: 100%;
    order: 0;
  }
  .tab-content .tab-pane .main-col .col-right.on-desktop{
    padding-top: 15px;
    border: 0;
    display: flex;
    flex-wrap: wrap;
  }
  .tab-content .tab-pane .main-col .col-right .MuiBox-root-7, .tab-content .tab-pane .main-col .col-right .MuiBox-root-6{
    min-width: 150px;
  }
  .tab-content .tab-pane .main-col .col-right .MuiBox-root-4{
    padding-left: 0;
    padding-right: 0;
  }
  .tab-content .tab-pane .main-col .col-right.on-desktop .MuiContainer-root{
    order: 0;
    padding: 0;
  }
  .tab-content .tab-pane .main-col .col-right .multiplier-wrap{
    flex-wrap: wrap;
    order: 3;
  }
  .tab-content .tab-pane .main-col .col-right .multiplier-wrap .multiplier-plusTen, .multiplier-wrap .multiplier{
    width: 100% !important;
  }
  .tab-content .tab-pane .main-col .col-right .multiplier-wrap .controlBtn{
    width: 200px;
    font-size: 20px;
    padding: 10px 15px;
  }
  .tab-content .tab-pane .main-col .col-right .multiplier-wrap h4{
    font-size: 20px;
    margin-bottom: 10px;
  }
  .tab-content .tab-pane .main-col .col-right .main-wrap11{
    padding: 80px 0px 50px;
    width: 100%;
    order: 2;
  }
  .tab-content .tab-pane .main-col .col-right .running-circle{
    max-width: 190px;
  }
  .coolPatterns-wrap .col-md-12 .col-md-4{
    margin-bottom: 15px;
  }
  h2.Investment-Experience{
    font-size: 36px !important;
    padding: 0 20px;
    line-height: 40px;
 
  }
  h2.pricing_title{
    font-size: 36px !important;
    padding: 0 20px;
    line-height: 40px;
  }
  p.Alternative-Data{
    font-size: 20px !important;
  }
  .tab-content .tab-pane .main-col .col-right.on-desktop h3.financial-modeling{
    width: 100%;
    order: 4;
  }
  .tab-content .tab-pane .main-col .col-right.on-desktop .coolPatterns-wrap.row{
    order: 5;
  }
  
}
@media (max-width: 424px){
  .inverstment-dropdown-tabs .inverstment-dropdown-mobile .dropdown-toggle{
    max-width: 300px;
    font-size: 16px !important;
    padding: 13px 14px;
  }
  .inverstment-dropdown-tabs .inverstment-dropdown-mobile .dropdown-menu{
    max-width: 300px;
  }
  .inverstment-dropdown-tabs .inverstment-dropdown-mobile .dropdown-toggle:before{
    font-size: 16px !important;
  }
  .tab-content .tab-pane .main-col .col-price-details{
    padding: 0;
  }
  .tab-content .tab-pane .main-col .col-price-details ul li p{
    font-size: 15px !important;
  }
  .tab-content .tab-pane .main-col .col-price-details ul li{
    padding: 17px 13px;
  }
  .tab-content .tab-pane .main-col .col-right .MuiBox-root-7, .tab-content .tab-pane .main-col .col-right .MuiBox-root-6{
    min-width: 130px;
  }
  .tab-content .tab-pane .main-col .col-right .multiplier-wrap .controlBtn{
    width: 180px; 
    font-size: 17px;
    padding: 10px;
  }
  .tab-content .tab-pane .main-col .col-right .multiplier-wrap h4{
    font-size: 17px;
  }
    .tab-content .tab-pane .main-col .col-right .main-wrap11{
    padding: 20px 0;
  }
  .tab-content .tab-pane .main-col .col-right .running-circle{
    max-width: 140px;
  }
}
/* //responsive - footer */
